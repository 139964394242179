import pdfs, { IPdfDocumentDescription } from '@iris/assets/pdfs'
import { ALTPAYER, CONSULTANT, CUSTOMER, DEPOSIT_ALT_PAYER, DIRECT_DEBIT_ALT_PAYER, ESTIALABSPLANS, STEP_CONFIRM_EMAILS_RECEIVED,
  STEP_FAMILY_INFORMATION, STEP_FINANCE, STEP_GET_INITIAL_LOCATION, STEP_PRIVACY_PROMPT, TIMEZONE, PROMO_CODES, IrisPromoCodes,
  ITEMS_FOR_SALE, IrisPaygRevisionPricingOption, STEP_FIND_PRESENTATION, EstiaLabsPricingModel } from '@iris/constants'
import { ICourseIdPair, ICourseMap } from '@iris/regmodel'
import states from '@iris/states.json'
import trackjsPlugin from '@iris/trackjs-vuex'
import { PDFDocument, PDFTextField } from 'pdf-lib'
import axios from 'axios'

import {
  filter as _filter,
  find as _find,
  findIndex as _findIndex,
  isArray as _isArray,
  keyBy as _keyBy,
  mapValues as _mapValues,
  merge as _merge,
  some as _some,
  times as _times,
  uniq as _uniq,
  reduce,
  map,
  compact
} from 'lodash'

import moment, { Moment } from 'moment-timezone'
import randomize from 'randomatic'
import Vue from 'vue'
import { getField, updateField } from 'vuex-map-fields'
import createPersistedState from 'vuex-persistedstate'
import actionsForApis from './actionsForApis'
import gettersForApis from './gettersForApis'
import gettersForPdfFields from './gettersForPdfFields'
import { reducer, setState } from './helpers'
import logToS3Plugin from './logger'
import watchStoreForStateChange from './plugin'
import storeDataMigrationPlugin from './storeDataMigrationPlugin'
import payments from './payments'
import instalments, { instalmentModulePlugin } from './instalments'
import { IChild, IStoreParams, IrisStore, IrisState, IrisGetters, DropDownOption, PaymentMethods, IDocumentGetter, IDocumentPackDocument, IPersonInfo, IAddressInfo, IModuleCounts, ICourseSet, CourseTypes, PartialNullable, IDocument, PayerPossibleSigners, TDepositAltPayer, TDirectDebitAltpayer, TAltPayerNames, TPaygMonthsDepositOptions, IItemPurchased, IItemPurchasedData, ExtendedEstiaLabsPricingModel } from './types'
import applyDefaultPromoCodes from './applyDefaultPromoCodes'
import { IResponseExistingAccountInfo, IResponseCreateAccount, AuthTokenResponse } from '@iris/api'
import { rangeFromObject, roundNumber, roundNumberDown, roundNumberUp } from '@iris/util'
import { NestJSApi } from '@iris/nestjs'
import { Presentation } from '@iris/nestjs-interfaces'
import { getAuthHeaders } from '~~/utils/authorisation'

function notEmpty<TValue> (value: TValue | null | undefined): value is TValue {
  return value !== null && value !== undefined
}

function valueAsArray<T>(value: T | T[]): T[] {
  if (Array.isArray(value)) {
    return value
  }
  return [value]
}

const CHILD_TEMPLATE = (): IChild => {
  return {
    firstName: null,
    lastName: null,
    gender: null,
    dob: null,
    courses: {
      maths: null,
      literacy: null,
      mathsGCSE: null,
      mathsN5: null,
      reading: null
    },
    skipIA: {
      maths: true
    },
    homeSchooling: false
  }
}

/**
 * for module price calculation
 * (NUM_OF_MODULES - 1) * MODULE_PRICE
 * for finance - remove gcse courses from list
 */
const debug = process.env.NODE_ENV !== 'production'

// put any stuff in here which you don't want persisted
// eg state of api calls etc...

export default ({ regHitId, regModelMap, createIrisApi, consultant, userId, branchOffice, initialStoreState = {}, authenticated, persistedKey = 'iris-lite-data', forceLogoutOnCompletion = true, irisDocumentsBucket }: IStoreParams): IrisStore => {

  const nuxtApp = useNuxtApp()
  const config = useRuntimeConfig()

  try {
    if (persistedKey in localStorage) {
      const existingData = <IrisState>JSON.parse(localStorage[persistedKey])
      if (existingData.regModelMap.REGCODE !== regModelMap!.REGCODE) {
        localStorage.removeItem(persistedKey)
      }
    }
  } catch (e) {}

  const irisInitialStoreState: Omit<IrisState, 'authenticated' | 'position' | 'instalments' | 'payments'> = _merge({
    upgradingAccount: false,
    initialTime: (new Date()).toISOString(),
    privacyPolicyAccept: false,
    privacyPolicyAcceptDate: null,
    securekey: randomize('A0', 16),
    branchOffice: branchOffice || '',
    userId: userId || 0,
    regHitId: regHitId || 0,
    regModelMap: regModelMap || {},
    consultant: consultant || '',
    timeDifference: 0,
    apiException: null,
    forceLogoutOnCompletion,
    familyInformation: {
      title: null,
      firstName: '',
      lastName: '',
      email: '',
      emailConfirm: '',
      alternativeEmail: '',
      alternativeEmailConfirm: '',
      mobilePhone: '',
      sendUnlockCodeAsPlainMessage: false,
      homePhone: '',
      workPhone: '',
      sendLoginDetailsToMobilePhone: true,
      authorisedPersons: '',
      dob: ''
    },
    altPayer: false,
    altPayerInformation: {
      title: null,
      firstName: '',
      lastName: '',
      dob: '',
      email: '',
      emailConfirm: '',
      address1: '',
      address2: '',
      city: '',
      postcode: '',
      country: 'GB',
      loqateResponse: null,
      mobilePhone: ''
    },
    courseSelections: {
      maths: [],
      literacy: [],
      mathsGCSE: [],
      mathsN5: [],
      reading: []
    },
    address: {
      address1: '',
      address2: '',
      city: '',
      postcode: '',
      state: null,
      country: 'GB',
      loqateResponse: null
    },
    promoCodes: [], // promo codes activated
    finance: {
      immediateNoticeProvided: false,
      depositAltPayer: false, // is the deposit paid for by the alt payer?
      depositAltPayerInfo: { // this is for a custom name here (other than customer or alt payer above)
        title: null,
        firstName: '',
        lastName: '',
        dob: '',
        email: '',
        emailConfirm: '',
        address1: '',
        address2: '',
        city: '',
        postcode: '',
        country: 'GB',
        loqateResponse: null
      },
      paymentMethod: null, // ltl/aps etc...
      monthsDeposit: 3, // for PAYG 1,2 or 3 months this sets the deposit amount
      deposit: 100, // for ltl only defaulted to £100
      depositReceiptNumber: null, // typed in from EE website
      defaultDepositAmount: 100,
      depositPaymentMethod: null, // either DEFERRED, CREDITCARD, CASH
      deferredDepositDate: null, // if above is DEFERRED
      monthsTerm: null,
      decision: null, // used by referred process only.
      referred: false, // this is a flag for FR
      selfDeclaration: null,
      complianceComments: 'Given the customer\'s Affordability Statement, there are no additional comments.',
      proofID: null,
      proofIncome: null,
      pricingDate: null,
      recurlyDDPaymentMethodId: null,
      dd: {
        ddAltPayerInfo: { // this is for a custom name here (other than customer or alt payer above)
          title: null,
          firstName: '',
          lastName: '',
          dob: '',
          email: '',
          emailConfirm: '',
          address1: '',
          address2: '',
          city: '',
          postcode: '',
          country: 'GB',
          loqateResponse: null
        },
        ddAltPayer: false, // is the direct debit in the alt payers name, or set to OTHER
        accountHolder: null,
        bankName: null,
        bankAddress: null,
        sortCode: null,
        accountNumber: null,
        confirmation: null
      }
    },
    ltlExtraConfirmations: false,
    cashDiscountTrial: false,
    capSalesPrice5k: false, // XMAS19 promo code
    defaultOnPromoCodesApplied: false, // boolean flag to indicate if promo codes have been applied by plugin
    createAccountResult: null,
    completionCertificateData: null,
    children: _times(1, CHILD_TEMPLATE),
    submitting: false,
    step: STEP_FIND_PRESENTATION,
    submitMessage: null,
    unlockCode: null,
    flashMessage: null,
    documents: [],
    savedFamilyInfo: null,
    videoConfirmation: {
      startTime: null, // start time for video
      endTime: null, // end time for video
      progress: 0, // progress for video
      accept: null // timestamp for accepting video playback
    },
    otherItemsPurchased: [],
    discount: 0,
    discountAmount: 0,
    paygFifty: true,
    allowApsSubscriptions: true, // show up new APS Subscription via Stripe style aps plan,
    documentationOnlyMode: false,
    assessmentId: null,
    backLink: undefined,
    stripeSubscriptionEnabled: false,
    fixedSubscriptionModelEnabled: false,
    bf90hack: false,
    recurlyDirectDebit: true,
    recurlyAccountId: null,
    recurlyApiLastError: null,
    dynamicsPresentationId: null,
    directDebitUnableToBeCompletedOnNight: false,
    recurlyByPassUsed: false,
    priceOverride: null,
    singleMonthInitialSubscription: false,
    newSigningProcessConfirmationId: null,
    titleOverride: null,
    subscriberId: null,
    forcePickupSale: false,
    bypassPayment: false,
    numberOfSessionsOverride: null,
    monthsInitialPayment: 1,
    overrideInitialPaymentAmount: null,
    overrideExtraSessionsCost: null,
    planCodeSuffix: null,
    overrideMembershipFee: null,
    salesMode: null,
    calendarBookingOverride: null
  } as Omit<IrisState, 'authenticated' | 'position' | 'instalments' | 'payments'>, initialStoreState)

  const store = new IrisStore({
    modules: {
      payments: payments(initialStoreState.payments),
      instalments: instalments(initialStoreState.instalments)
    },
    plugins: [
      ...(persistedKey ? [createPersistedState({ setState, reducer, key: persistedKey })] : []),
      storeDataMigrationPlugin,
      watchStoreForStateChange,
      trackjsPlugin,
      logToS3Plugin(irisDocumentsBucket),
      applyDefaultPromoCodes,
      store => {
        // force logoutOnCompletion value to be set from function initialising this and not persisted state
        if (store.state.forceLogoutOnCompletion !== forceLogoutOnCompletion) {
          store.commit('updateField', { path: 'forceLogoutOnCompletion', value: forceLogoutOnCompletion })
        }
      },
      (store: IrisStore) => {
        store.irisApi = createIrisApi(store)
        store.irisApi.timeAdjustment.subscribe(difference => store.commit('updateTimeDifference', difference))
        store.nestApi = new NestJSApi({
          api: {
            getAuthToken() {
                return getAuthHeaders(nuxtApp.$auth).then(({ Authorization }): AuthTokenResponse => {
                  if (Authorization) {
                    const [ tokenType, accessToken ] = Authorization.split(' ', 2)
                    return {
                      accessToken,
                      tokenType: tokenType as 'Bearer',
                      expiresIn: 30,
                      message: 'ok',
                      status: true
                    }
                  }
                  throw new Error('no logged in no token')
                })
            },
          },
          axios: nuxtApp.$axios,
          prefix: config.public.irisNestUrl || (store.state.regModelMap.INSTITUTECODE === 'ESTIA' ? '/iris-estia' : '/iris')
        })
      },
      instalmentModulePlugin({ instalmentsRoot: 'instalments', paymentsRoot: 'payments' })
    ],
    strict: debug,
    state: {
      ...irisInitialStoreState,
      position: null, // don't accept position from outside
      authenticated: !!authenticated // take authenticated from above only
    } as IrisState, // force to IrisState as the modules are currently forced on
    getters: {
      moment: (state) => (altTime: any) => moment.tz(altTime, TIMEZONE).add(state.timeDifference, 'milliseconds'),
      initialTime: (state, getters: IrisGetters): Moment => getters.moment(state.initialTime),
      consultant: (state) => state.consultant,
      contractAmount: (state, getters: IrisGetters) => {
        return getters.saleValue
      },
      altPayerNameOptions: (state, getters: IrisGetters): PayerPossibleSigners<DropDownOption<TDirectDebitAltpayer>> => ({
        CUSTOMER: { text: `${state.familyInformation.firstName} ${state.familyInformation.lastName}`, value: false },
        ALTPAYER: { text: (state.altPayerInformation.firstName && state.altPayerInformation.lastName) ? `${state.altPayerInformation.firstName} ${state.altPayerInformation.lastName}` : 'Alt Payer', value: true },
        DEPOSIT_ALT_PAYER: { text: (getters['payments/firstPaymentWithDepositAltPayer'] && state.finance.depositAltPayerInfo.firstName && state.finance.depositAltPayerInfo.lastName) ? `${state.finance.depositAltPayerInfo.firstName} ${state.finance.depositAltPayerInfo.lastName}` : 'Other', value: DEPOSIT_ALT_PAYER },
        DIRECT_DEBIT_ALT_PAYER: { text: (state.finance.dd.ddAltPayer === DIRECT_DEBIT_ALT_PAYER && state.finance.dd.ddAltPayerInfo.firstName && state.finance.dd.ddAltPayerInfo.lastName) ? `${state.finance.dd.ddAltPayerInfo.firstName} ${state.finance.dd.ddAltPayerInfo.lastName}` : 'Other', value: DIRECT_DEBIT_ALT_PAYER }
      }),
      depositPayerNameOptions: (state, getters: IrisGetters): DropDownOption<TDepositAltPayer>[] => [
        getters.altPayerNameOptions[CUSTOMER] as DropDownOption<TDepositAltPayer>,
        ...(getters.altPayer && state.altPayerInformation.firstName && state.altPayerInformation.lastName) ? [getters.altPayerNameOptions[ALTPAYER] as DropDownOption<TDepositAltPayer>] : [],
        getters.altPayerNameOptions[DEPOSIT_ALT_PAYER] as DropDownOption<TDepositAltPayer>
      ],
      directDebitPayerNameOptions: (state, getters: IrisGetters): DropDownOption<TDirectDebitAltpayer>[] => {
        const options: DropDownOption<TDirectDebitAltpayer>[] = [getters.altPayerNameOptions[CUSTOMER], getters.altPayerNameOptions[DIRECT_DEBIT_ALT_PAYER]]
        if (getters['payments/firstPaymentWithDepositAltPayer'] && state.finance.depositAltPayerInfo.firstName && state.finance.depositAltPayerInfo.lastName) { // if a name is chosen in any of the payment options
          options.splice(1, 0, getters.altPayerNameOptions[DEPOSIT_ALT_PAYER])
        }
        if (getters.altPayer && state.altPayerInformation.firstName && state.altPayerInformation.lastName) {
          options.splice(1, 0, getters.altPayerNameOptions[ALTPAYER])
        }
        return options
      },
      finalSaleType: (state, getters: IrisGetters): Exclude<PaymentMethods, 'APS_SUBSCRIPTION'> => {
        return getters.paymentMethodForCss as any
      },
      documents: (state, getters: IrisGetters): IDocumentGetter[] => {
        return state.documents.map((doc, index) => {
          // this is the data basically from pdf/index.js but functions called to fill in correct signers if required
          let pdfDoc = getters.documentPackById[doc.id]
          if (!pdfDoc) {
            return null
          }
          return {
            ...pdfDoc,
            ...doc,
            index
          }
        }).filter(notEmpty)
      },
      documentsById: (_state, getters: IrisGetters): Record<string, IDocumentGetter> => _keyBy(getters.documents, 'id'),
      documentSignerNames: (state) => ({
        [CUSTOMER]: `${state.familyInformation.firstName} ${state.familyInformation.lastName}`,
        [CONSULTANT]: state.consultant,
        [ALTPAYER]: `${state.altPayerInformation.firstName} ${state.altPayerInformation.lastName}`,
        [DIRECT_DEBIT_ALT_PAYER]: `${state.finance.dd.ddAltPayerInfo.firstName} ${state.finance.dd.ddAltPayerInfo.lastName}`,
        [DEPOSIT_ALT_PAYER]: `${state.finance.depositAltPayerInfo.firstName} ${state.finance.depositAltPayerInfo.lastName}`
      }),
      promoCodeData: (state, getters: IrisGetters, rootState, rootGetters: IrisGetters) => (promoCode: string, { ignoreExtraChecks }: { ignoreExtraChecks: boolean } = { ignoreExtraChecks: false }) => {
        const isValid = (code: IrisPromoCodes) => typeof code.isValid !== 'function' || code.isValid(state, getters, rootState, rootGetters)
        return PROMO_CODES.find(p =>
          (p.code.toUpperCase() === promoCode.toUpperCase()) && // code matches string
          (ignoreExtraChecks ||
            (rangeFromObject(p).contains(getters.initialTime) && // code has valid dates
            isValid(p) && // code has valid IsValid function return
            getters.promoCodes.every(isValid))
          ) // all other active codes are still valid too!
        )
      },
      promoCodes: (state): IrisPromoCodes[] => {
        return state.promoCodes.map(code => PROMO_CODES.find(p => p.code.toUpperCase() === code.toUpperCase()) || { code })
      },
      paymentMethods: (state): DropDownOption<PaymentMethods>[] => {
        if (state.fixedSubscriptionModelEnabled) {
          return [
            { value: 'SP01', text: 'SP01' },
            { value: 'SP06', text: 'SP06' },
            { value: 'SP12', text: 'SP12' },
            { value: 'CP12', text: 'CP12' },
            { value: 'LTS10', text: 'LTS10' },
            { value: 'SP03', text: 'SP03' },
            { value: 'SP30', text: 'SP30' },
            { value: 'GCSE', text: 'GCSE' }
          ]
        }
        // default "normal" set of payment methods
        const defaultPaymentMethods: DropDownOption<PaymentMethods>[] = [
          { value: 'LTL', text: 'LTL' },
          { value: 'PAYG', text: 'PAYG' },
          { value: 'APS', text: 'APS V1' },
          { value: 'CASH', text: 'CASH' }
        ]
        // new aps subscription method
        if (state.allowApsSubscriptions) {
          // insert APS stripe subscription type in after APS
          defaultPaymentMethods.splice(3, 0, {
            value: 'APS_SUBSCRIPTION', text: 'APS'
          })
        }
        // other flags remove methods
        return defaultPaymentMethods.filter(method => {
          if (state.bf90hack) {
            return method.value === 'APS_SUBSCRIPTION'
          }
          if (state.paygFifty) {
            return method.value === 'PAYG' || method.value === 'CASH' || method.value === 'APS_SUBSCRIPTION'
          }
          // limit methods for some promo codes
          return (!state.capSalesPrice5k || method.value !== 'PAYG') // no PAYG for XMAS19
        })
      },
      paygDepositAmountFn: (_state, getters: IrisGetters) => (months: TPaygMonthsDepositOptions) => {
        const monthlyInstallment = getters.monthlyInstallmentFn(months)
        return getters.totalOfOtherItemsPurchased / 100 + (monthlyInstallment ? monthlyInstallment * months : 0)
      },
      paymentAmountRequiredNow: (state, getters: IrisGetters): number | null => {
        if (getters.currentNewPricingPlan) {
          if (getters.currentNewPricingPlan.upfrontPayment) {
            return getters.currentNewPricingPlan.upfrontPayment.amount
          }
          return getters.currentNewPricingPlan.membershipFee
        }
        return null
      },
      paygRevisionPricing: (state, getters: IrisGetters) => state.finance.paymentMethod === 'PAYG' && getters.moduleCount.totalWithoutRevision === 0 && getters.moduleCount.mathsRevision === 1,
      documentUploadSummary: (state) => {
        const summary = state.documents.reduce((summary, document) => {
          if (document.uploading || document.url || document.uploadError) {
            summary.total += 1
            let size = (document.size || 800 * 1024) + (100 * 1024) // assume 800K size but add 100k for a resolved blob promise
            summary.totalSize += size
            if (document.uploadError) {
              summary.errorCount += 1
            } else if (document.uploadAt && document.url) {
              summary.uploadedCount += 1
              summary.totalProgress += size
            } else {
              if (document.size) {
                // add 100k to progress for resolved blob promise
                summary.totalProgress += (100 * 1024)
              }
              // add actual uploaded amount
              summary.totalProgress += (document.uploaded || 0)
            }
          }
          return summary
        }, {
          total: 0,
          uploadedCount: 0,
          errorCount: 0,
          totalSize: 0,
          totalProgress: 0,
          totalPercent: 0
        })
        summary.totalPercent = Math.round((summary.totalProgress / summary.totalSize) * 100) || 0
        return summary
      },
      requiresDirectDebitFirstDate: (state, getters: IrisGetters) => {
        return getters.hasDirectDebit || state.directDebitUnableToBeCompletedOnNight
      },
      hasDirectDebit: (state, getters: IrisGetters) => {
        if (getters.isSubscriptionType) {
          if (getters.currentNewPricingPlan && getters.currentNewPricingPlan.noDirectDebit) {
            return false
          }
          // direct debit isn't able to be completed on the night.
          if (state.directDebitUnableToBeCompletedOnNight) {
            return false
          }
          if (getters.currentNewPricingPlan && getters.immediateNoticeProvided) {
            if (getters.currentNewPricingPlan.paymentLengthMonths < getters.currentNewPricingPlan.contractLengthMonths) {
              // the initial payment isn't enough to cover the contract length
              return true
            }
          }
          // also return false when immediate notice provided
          return !(getters.immediateNoticeProvided || getters.isStripeDirectDebit) // mutually exclusive with stripe
        }
        // only enable for LTL and not duology
        return (getters.finalSaleType === 'LTL' && state.finance.decision !== 'DFA')
      },
      isSubscriptionType: (state, getters: IrisGetters): boolean => {
        // used for extra items to purchase
        return state.finance.paymentMethod === 'APS_SUBSCRIPTION' || state.finance.paymentMethod === 'PAYG' || getters.isSubscriptionPlanDec2021
      },
      isSubscriptionPlanDec2021: (state, getters: IrisGetters): boolean => {
        return !!getters.currentNewPricingPlan
      },
      minimumTerm: (state, getters: IrisGetters): number => {
        if (getters.currentNewPricingPlan) {
          return getters.currentNewPricingPlan.termLength?.asMonths()
        }
        return 0
      },
      minimumNoticeTerm: (state, getters: IrisGetters): number => {
        return getters.minimumTerm || (getters.currentNewPricingPlan ? getters.currentNewPricingPlan.termLength?.asMonths() : 0)
      },
      hasTeacherHelpline: (): boolean => true,
      isStripeDirectDebit: (state, getters: IrisGetters): boolean => {
        return state.stripeSubscriptionEnabled && ['APS', 'PAYG'].indexOf(getters.finalSaleType) > -1
      },
      ltlExtraConfirmations: (state, getters): boolean => getters.finalSaleType === 'LTL' && state.ltlExtraConfirmations,
      ltlExtraConfirmationMobilePhone: (state, getters): string => getters.altPayer ? state.altPayerInformation.mobilePhone : state.familyInformation.mobilePhone,
      documentPackById: (_state, getters: IrisGetters): Record<string, IDocumentGetter> => _keyBy(getters.documentPack, 'id'),
      documentPack: (state, getters: IrisGetters): IDocumentPackDocument[] => {
        /** documents as part of what needs to be signed */
        let documents: Array<IPdfDocumentDescription & Partial<Pick<IDocumentPackDocument, 'originalId' | 'paymentIndex'>>> = []

        /** supported documents with branded versions list @see pdfs/index.ts */
        type BrandedDocumentNames = 'COMPLETION_CERTIFICATE' | 'DIRECT_DEBIT_GFG_GOCARDLESS' | 'OFFICE_COVERSHEET' | 'TERMS_AND_CONDITIONS'
        /** depending on institute append the estia version or leave original exemplar versions */
        const brandedDocument = (name: BrandedDocumentNames): typeof documents[0] => {
          const newName: `ESTIA_${BrandedDocumentNames}` = `ESTIA_${name}`
          if (!(newName in pdfs)) {
            throw new Error(`${newName} not found in pdf documents`)
          }
          return pdfs[newName]
        }

        // "Normal" sale type
        if (!state.address.state) {
          return []
        }
        // 3 documents only
        documents.push(brandedDocument('OFFICE_COVERSHEET'))
        if (state.recurlyResponse && state.recurlyResponse.mandateSummary) {
          documents.push(brandedDocument('DIRECT_DEBIT_GFG_GOCARDLESS'))
        }
        documents.push(brandedDocument('COMPLETION_CERTIFICATE'))
        documents.push(brandedDocument('TERMS_AND_CONDITIONS'))

        // mark out who is to sign each document
        return documents.map(document => {
          let doc: IDocumentPackDocument = {
            ...document,
            originalId: document.originalId || document.id
          }
          return doc as IDocumentPackDocument
        })
      },
      allDocumentsSigned: (_state, getters: IrisGetters) => {
        return true // TODO remove
      },
      saleValue: (state, getters: IrisGetters): number => {
        return getters.totalOfOtherItemsPurchased / 100 + getters.saleValueWithoutExtras
      },
      saleValueWithoutExtras: (state, getters: IrisGetters): number => {
        if (getters.currentNewPricingPlan && getters.currentNewPricingPlan.oneOffPaymentText) {
          return getters.currentNewPricingPlan.price
        } else if (getters.paygRevisionPricing) { // this has already been discounted
          let priceOption = _find(getters.irisRevisionPricing, { monthsTerm: state.finance.monthsTerm || undefined })
          return priceOption ? priceOption.total : getters.modulePrice
        }
        // make sure total price is a whole number
        // old previous 50% cash discount moved into constants.ts
        return roundNumberUp(getters.moduleCount.totalForFinanceCalculations * getters.modulePrice, 0)
      },
      subscriptionUnitPriceCents: (state: IrisState, getters: IrisGetters): number => {
        if (state.finance.paymentMethod === 'APS_SUBSCRIPTION') {
          if (getters.irisPricing.apsSubscriptionUnitPriceCents) {
            return getters.discount(getters.irisPricing.apsSubscriptionUnitPriceCents(getters.moduleCount.totalForFinanceCalculations), 100)
          }
          // defaults to 60 months old pricing if not set
          return getters.modulePrice / 60 * 100
        } else if (state.finance.paymentMethod === 'APS') {
          // depends on number of months selected
          return (getters.modulePrice / (state.finance.monthsTerm || 60)) * 100
        } else if (state.finance.paymentMethod === 'PAYG') {
          // calculated on 48 months * 1.3
          if (state.allowApsSubscriptions) { // takes precedence over payg50 pricing
            if (getters.irisPricing.paygUnitPriceCents) {
              return getters.discount(getters.irisPricing.paygUnitPriceCents(getters.moduleCount.totalForFinanceCalculations), 100)
            } else {
              return ((getters.modulePrice * 1.1) / 60) * 100 // trial payg pricing 10% more than the aps subscription below
            }
          }
          if (state.paygFifty) {
            return (getters.modulePrice / 60) * 100 // Normal Payg pricing which has already been discounted
          }
          return ((getters.modulePrice * 1.3) / 48) * 100 // Normal Payg pricing which has already been discounted
        }
        // CASH & LTL doesn't make sense
        return 0
      },
      monthlyInstallmentFn: (state: IrisState, getters: IrisGetters) => (monthsDeposit: number): number | undefined => {
        const otherItemsMonthlyAmount: number = getters.monthlyTotalOfOtherItemsPurchased / 100
        if (getters.currentNewPricingPlan) {
          return getters.currentNewPricingPlan.membershipFee + otherItemsMonthlyAmount
        }
        return 0
      },
      monthlyInstallment: (state: IrisState, getters: IrisGetters): number | undefined => {
        return getters.monthlyInstallmentFn(state.finance.monthsDeposit)
      },
      altPayer: (state, getters: IrisGetters) => ['LTL', 'CASH', 'CP12'].indexOf(getters.finalSaleType) > -1 && state.altPayer,
      itemsAvailableForSale: (state, getters: IrisGetters): IItemPurchasedData => {
        if (state.finance.paymentMethod === 'APS_SUBSCRIPTION') {
          return [] // for aps subscription no items are available for purchase
        }
        return ITEMS_FOR_SALE.filter(item => {
          if (state.finance.paymentMethod === 'PAYG' && !getters.paygRevisionPricing && state.paygFifty) {
            return true
          }
          return item.monthly === false
        }).map((item):IItemPurchasedData[0] => ({
          ...item,
          description: typeof item.description === 'function' ? item.description(getters.discount(item.amountInCents, 100)) : item.description
        }))
      },
      discount: (state): IrisGetters['discount'] => (amount: number, factor: 0 | 1 | 100) => amount - (amount * state.discount / 100) - (state.discountAmount * factor),
      otherItemsPurchased: (state, getters: IrisGetters):Array<IItemPurchased & { invoiceDescription: string }> => {
        return state.otherItemsPurchased.filter(item => getters.itemsAvailableForSale.some(i => item.id === i.id)).map(i => {
          const originalItem = getters.itemsAvailableForSale.find(a => a.id === i.id)
          return {
            ...i,
            invoiceDescription: originalItem ? originalItem.invoiceDescription : i.id
          }
        })
      },
      monthlyTotalOfOtherItemsPurchased: (_state, getters: IrisGetters): number => {
        return getters.discount(reduce(getters.otherItemsPurchased.filter(i => i.monthly), (total, item) => total + (item.quantity * item.amountInCents), 0), 100)
      },
      totalOfOtherItemsPurchased: (_state, getters: IrisGetters): number => {
        return getters.discount(reduce(getters.otherItemsPurchased.filter(i => !i.monthly), (total, item) => total + (item.quantity * item.amountInCents), 0), 100)
      },
      altPayerDirectDebit: (state, getters: IrisGetters) => {
        if (state.finance.dd.ddAltPayer === true && !getters.altPayer) {
          // alt payer is disabled but direct debit was set to alt payer
          return false
        }
        if (!getters['payments/firstPaymentWithDepositAltPayer'] && state.finance.dd.ddAltPayer === DEPOSIT_ALT_PAYER) {
          // the deposit alt payer isn't in play but we have this set to deposit alt payer return customer (false)
          return false
        }
        return state.finance.dd.ddAltPayer
      },
      getInfoForPayerType: (state) => (payerType: TAltPayerNames): IPersonInfo & IAddressInfo => {
        if (payerType === 'DIRECT_DEBIT_ALT_PAYER') {
          return state.finance.dd.ddAltPayerInfo
        }
        if (payerType === 'DEPOSIT_ALT_PAYER') {
          return state.finance.depositAltPayerInfo
        }
        if (payerType === 'ALTPAYER') {
          return state.altPayerInformation
        }
        // customer info
        return { ...state.familyInformation, ...state.address }
      },
      altPayerDirectDebitInfo: (state, getters: IrisGetters): IPersonInfo & IAddressInfo => {
        if (getters.altPayerDirectDebit === DIRECT_DEBIT_ALT_PAYER) {
          return state.finance.dd.ddAltPayerInfo
        }
        if (getters.altPayerDirectDebit === DEPOSIT_ALT_PAYER) {
          return state.finance.depositAltPayerInfo
        }
        if (getters.altPayerDirectDebit === true) {
          return state.altPayerInformation
        }
        // default to customer name this is like alt payer info
        return { ...state.familyInformation, ...state.address }
      },
      lastInstallmentAmount: (state, getters: IrisGetters): number | undefined => {
        if (getters.monthlyInstallment && state.finance.monthsTerm !== null) {
          return roundNumber(getters.financeAmount - ((state.finance.monthsTerm - 1) * getters.monthlyInstallment))
        }
      },
      getField,
      stateList: state => {
        return states[state.address.country]
      },
      irisStartTime: (state, getters: IrisGetters) => state.privacyPolicyAcceptDate ? moment(state.privacyPolicyAcceptDate) : getters.moment(),
      irisPricing: (state, getters: IrisGetters): IrisPricingModel => {
        const pricingTime = state.finance.pricingDate ? moment.tz(state.finance.pricingDate, TIMEZONE).startOf('day') : getters.initialTime
        const models = ESTIALABSPLANS.filter((m): m is IrisPricingModel => m.modelType === 'classic')
        return _find(models, priceModel => rangeFromObject(priceModel).contains(pricingTime)) || models[0]
      },
      currentNewPricingPlan: (state, getters: IrisGetters): ExtendedEstiaLabsPricingModel | undefined => {
        const pricingTime = state.finance.pricingDate ? moment.tz(state.finance.pricingDate, TIMEZONE).startOf('day') : getters.initialTime
        const models = ESTIALABSPLANS.filter((m): m is EstiaLabsPricingModel => m.modelType === 'estialabs' && m.priceCode === state.finance.paymentMethod)
        const foundModel = _find(models, model => rangeFromObject(model).contains(pricingTime))
        if (foundModel) {
          let currentModel: ExtendedEstiaLabsPricingModel = {
            ...foundModel,
            absoluteMaxStudentsLimit: foundModel.maxStudentsLimit + (foundModel.allowExtraPaidStudents?.limit ?? 0),
            addons: undefined
          }
          if (state.numberOfSessionsOverride) {
            currentModel = {
              ...currentModel,
              includedSessions: state.numberOfSessionsOverride
            }
          }
          if (state.bypassPayment) {
            return {
              ...currentModel,
              membershipFee: 0,
              noDirectDebit: true
            }
          }
          if (typeof state.overrideMembershipFee === 'number') {
            currentModel = {
              ...currentModel,
              membershipFee: state.overrideMembershipFee
            }
          }
          if (state.discount) {
            currentModel = {
              ...currentModel,
              membershipFee: roundNumber(currentModel.membershipFee - (currentModel.membershipFee * state.discount/100))
            }
          }
          if (state.monthsInitialPayment > 1 || state.overrideInitialPaymentAmount !== null) {
            currentModel = {
              ...currentModel,
              upfrontPayment: {
                amount: roundNumber((state.overrideInitialPaymentAmount ?? currentModel.membershipFee) * state.monthsInitialPayment), //state.finance.monthsDeposit,
                period: moment.duration({ months: state.monthsInitialPayment })
              }
            }
          }
          if (currentModel.maxStudentsLimit && state.children.length > currentModel.maxStudentsLimit && currentModel.allowExtraPaidStudents) {
            const extraChildrenPurchasesQty = state.children.length - currentModel.maxStudentsLimit
            currentModel = {
              ...currentModel,
              maxStudentsLimit: state.children.length,
              membershipFee: currentModel.membershipFee + (currentModel.allowExtraPaidStudents.cost * extraChildrenPurchasesQty),
              addons: [{
                unitAmount: currentModel.allowExtraPaidStudents.cost,
                code: 'el_child',
                quantity: extraChildrenPurchasesQty
              }]
            }
          }
          if (state.promoCodes.includes('KLARNA')) {
            currentModel = {
              ...currentModel,
              initialPaymentTypes: ['cash', 'klarna', 'creditcard-recurly', 'creditcard-recurly-remote']
            }
          }
          if (typeof state.overrideExtraSessionsCost === 'number') {
            currentModel = {
              ...currentModel,
              extraSessionCost: [
                {
                  numberOfSessions: 1,
                  cost: state.overrideExtraSessionsCost
                }
              ]
            }
          }
          return currentModel
        }
        return undefined
      },
      paymentMethodForCss: (state): string => {
        return `${state.finance.paymentMethod}${state.planCodeSuffix ? state.planCodeSuffix : ''}`
      },
      irisRevisionPricing: (_state, getters: IrisGetters) => getters.irisPricing.revisionPricing.map<IrisPaygRevisionPricingOption>(price => ({
        deposit: getters.discount(price.deposit, 0),
        monthsTerm: price.monthsTerm,
        total: getters.discount(price.total, 0)
      })),
      modulePrice: (state, getters: IrisGetters) => {
        // console.log('fix module price in store')
        // console.trace('fix module price being called')
        return 99
      },
      minimumModules: () => 1,
      moduleCount: ({ courseSelections, finance, capSalesPrice5k, paygFifty, allowApsSubscriptions }): IModuleCounts => {
        let counts: IModuleCounts = {
          mathsWithoutRevision: courseSelections.maths.length,
          literacy: courseSelections.literacy.length,
          reading: courseSelections.reading.length,
          mathsRevision: (courseSelections.mathsGCSE.length || courseSelections.mathsN5.length) ? 1 : 0,
          maths: 0,
          total: 0,
          totalForFinanceCalculations: 0,
          totalWithoutRevision: 0
        }
        counts.maths = counts.mathsRevision + counts.mathsWithoutRevision
        counts.total = counts.maths + counts.literacy + counts.reading
        counts.totalWithoutRevision = counts.mathsWithoutRevision + counts.literacy + counts.reading
        // revision courses are free otherwise or it hasn't been selected by itself (above)
        counts.totalForFinanceCalculations = counts.totalWithoutRevision
        if (counts.totalForFinanceCalculations > 6) {
          // allow a free-be when over 6
          counts.totalForFinanceCalculations -= 1
        }
        if (capSalesPrice5k && counts.totalForFinanceCalculations > 11) {
          counts.totalForFinanceCalculations = 11
        }
        return counts
      },
      selectedCoursesLists: (state, getters: IrisGetters): ICourseSet<ICourseMap[]> => {
        // this is filtered by the selected courses
        return _mapValues<ICourseSet<ICourseMap[]>, ICourseMap[]>(getters.coursesList, (courseMapArray, subject) => {
          return _filter(courseMapArray, c => {
            return _findIndex(state.courseSelections[subject as CourseTypes], c.id) !== -1
          })
        })
      },
      childrenDefaultedCourseSelections: (_state, getters: IrisGetters) => {
        let defaultCourses: PartialNullable<ICourseSet<ICourseIdPair>> = {
          literacy: null,
          maths: null,
          mathsGCSE: null,
          mathsN5: null,
          reading: null
        }
        for (let s in getters.selectedCoursesLists) {
          let subject = s as CourseTypes
          // only auto activate course when it's the only choice (this means usually only N5)
          if (getters.selectedCoursesLists[subject].length === 1 && getters.coursesList[subject].length === 1) {
            defaultCourses[subject] = getters.selectedCoursesLists[subject][0].id
          }
        }
        return defaultCourses
      },
      financeAmount: (_state, getters: IrisGetters): number => {
        // if payment is required now use that amount otherwise use user supplied deposits
        return getters.saleValue - (getters.paymentAmountRequiredNow || (getters['payments/totalAmountPaidInCents'] / 100))
      },
      goodsDescription: (state, getters: IrisGetters): string => {
        return getters.currentNewPricingPlan?.description
      },
      coursesList: (state, getters: IrisGetters): ICourseSet<ICourseMap[]> => {
        if (!state.address.state) return { maths: [], mathsGCSE: [], mathsN5: [], reading: [], literacy: [] }
        function getKeyFromRegModelMap (key: string, defValue: ICourseMap[] = []): ICourseMap[] {
          let x = state.regModelMap[key]
          if (typeof x === 'object') {
            return x
          }
          return defValue
        }
        // let mathsN5 = []
        let mathsGCSE: ICourseMap[] = []
        // only get bonus content for LTL & CASH but shown when defaulted
        // show these only if NOT PAYG OR nothing has been selected as yet
        // if (['PAYG'].indexOf(state.finance.paymentMethod) === -1 || (state.courseSelections.maths.length + state.courseSelections.literacy.length + state.courseSelections.reading.length) === 0) {
        let mathsN5 = getKeyFromRegModelMap(`MATHEMATICSCOURSECURRICULUMYEARS_N5_${state.address.state}`)
        if (!mathsN5.length) {
          mathsGCSE = getKeyFromRegModelMap(`MATHEMATICSCOURSECURRICULUMYEARS_GCSE_${state.address.state}`)
        }
        // }
        const coursesList = {
          maths: getKeyFromRegModelMap(`MATHEMATICSCOURSECURRICULUMYEARS_${state.address.state}`, getKeyFromRegModelMap('MATHEMATICSCOURSECURRICULUMYEARS')),
          mathsGCSE,
          mathsN5,
          reading: getKeyFromRegModelMap('EARLYREADINGCOURSECURRICULUMYEARS'),
          literacy: getKeyFromRegModelMap(`LITERACYCOURSECURRICULUMYEARS_${state.address.state}`, getKeyFromRegModelMap('LITERACYCOURSECURRICULUMYEARS'))
        }
        if (typeof getters.currentNewPricingPlan?.coursesFilter === 'function') {
          return getters.currentNewPricingPlan.coursesFilter(coursesList)
        }
        return coursesList
      },
      buildPdfDocumentBlobPromise: (_state, getters: IrisGetters) => (index: number): Promise<Blob> => {
        const document = getters.documents.find(d => d.index === index)
        if (!document) {
          return Promise.reject(new Error(`Document not found at index ${index}`))
        }
        return axios.get<ArrayBuffer>(document.pdf, {
          responseType: 'arraybuffer'
        }).then(response => {
          return PDFDocument.load(response.data)
        }).then(pdfDoc => {
          const fieldsData = getters.fieldsForDocuments[document.id]
          const form = pdfDoc.getForm()
          const fields = form.getFields()
          for (const field of fields) {
            if (field instanceof PDFTextField && field.getName() in fieldsData) {
              const data = fieldsData[field.getName() as keyof typeof fieldsData]
              if (typeof data === 'string' || typeof data === 'number') {
                field.setText(data.toString())
              }
            }
            field.enableReadOnly()
          }
          form.flatten()

          return pdfDoc.save()
        }).then(bytes => {
          if (!bytes) {
            throw new Error('no document generated')
          }
          return new Blob([bytes])
        })
      },
      immediateNoticeProvided: (state, getters: IrisGetters): boolean => {
        if ((state.finance.paymentMethod === 'PAYG' && state.finance.monthsDeposit >= 3) || (getters.currentNewPricingPlan && getters.currentNewPricingPlan.immediateNoticeAllowed)) {
          return state.finance.immediateNoticeProvided
        }
        return false
      },
      /** full company name eg Exemplar Education or Estia Tuition */
      companyName: (state): string => 'Estia Labs',
      /** short company name eg Exemplar or Estia */
      shortCompanyName: (state): string => 'Estia',
      ...gettersForApis,
      ...gettersForPdfFields
    },
    mutations: {
      logout (state) {
        state.authenticated = false
      },
      updateField,
      updateTimeDifference (state, difference) {
        state.timeDifference = difference
      },
      apiError (state, exception) {
        // eslint-disable-next-line no-console
        console.log(exception)
        // doesn't matter any exception will stop iris
        state.apiException = exception
      },
      removeAllChildren (state) {
        state.children = []
      },
      removeChild (state, index) {
        state.children.splice(index, 1)
      },
      addChild (state) {
        if (state.children.length >= 5) return
        state.children.push({
          ...CHILD_TEMPLATE(),
          lastName: state.familyInformation.lastName
        })
      },
      submitting (state) {
        state.submitting = true
      },
      selectPresentation (state, pres?: Presentation) {
        function fixAllCaps (str: string) {
          if (str.toUpperCase() === str) {
            return `${str[0]}${str.substring(1).toLowerCase()}`
          }
          if (str.toLowerCase() === str) {
            return `${str[0].toUpperCase()}${str.substring(1)}`
          }
          return str
        }

        if (pres) {
          state.familyInformation.lastName = fixAllCaps(pres.lastName.trim())
          state.dynamicsPresentationId = pres.id
          if (pres.firstName) {
            state.familyInformation.firstName = fixAllCaps(pres.firstName.trim())
          }
          if (pres.mobile) {
            // remove all spaces and replace the +44 at the start with a 0
            state.familyInformation.mobilePhone = pres.mobile.trim().replace(/\s/g, '').replace(/^\+44/, '0')
          }
          if (pres.email) {
            state.familyInformation.email = pres.email.trim()
            state.familyInformation.emailConfirm = state.familyInformation.email
          }
          // just make sure postcode contains at least 1 word character a-z 0-9
          if (pres.postcode && /\w+/.test(pres.postcode)) {
            state.address.postcode = pres.postcode.trim()
            state.address.address1 = ''
            state.address.address2 = ''
            state.address.city = ''
            state.address.loqateResponse = null
          }
        }
        state.step = STEP_GET_INITIAL_LOCATION
      },
      updateExistingSubscriberInfo (state, subResponse: IResponseExistingAccountInfo) {
        const subscriber = subResponse.irisDataModel
        // address
        state.address.address1 = subscriber.address.address1 || ''
        state.address.address2 = subscriber.address.address2 || ''
        state.address.city = subscriber.address.city || ''
        state.address.postcode = subscriber.address.postcode || ''

        if (subscriber.address.country) {
          if (subscriber.address.country !== 'GB') {
            throw new Error('Country must be GB!')
          }
          state.address.country = subscriber.address.country
        }
        if (subscriber.address.state) {
          if (['ENG', 'WAL', 'SCO', 'NIE'].indexOf(subscriber.address.state) === -1) {
            throw new Error('State must be one of ENG / WAL / SCO / NIE')
          }
          state.address.state = subscriber.address.state as 'ENG' | 'WAL' | 'SCO' | 'WAL'
        }

        // basic name info
        state.familyInformation.firstName = subscriber.familyInformation.firstName || ''
        state.familyInformation.lastName = subscriber.familyInformation.lastName || ''
        state.familyInformation.dob = subscriber.familyInformation.dob || ''
        state.familyInformation.mobilePhone = subscriber.familyInformation.mobilePhone || ''
        state.familyInformation.homePhone = subscriber.familyInformation.homePhone || ''
        state.familyInformation.workPhone = subscriber.familyInformation.workPhone || ''
        state.familyInformation.email = subscriber.familyInformation.email || ''
        state.familyInformation.emailConfirm = subscriber.familyInformation.email || ''
        state.familyInformation.alternativeEmail = subscriber.familyInformation.alternativeEmail || ''
        state.familyInformation.alternativeEmailConfirm = subscriber.familyInformation.alternativeEmail || ''
        state.familyInformation.authorisedPersons = subscriber.familyInformation.authorisedPersons || ''
        state.familyInformation.title = subscriber.familyInformation.title || ''

        const courseSelectionMapper = (input: ICourseIdPair): ICourseIdPair => ({
          courseId: input.courseId,
          curriculumId: input.curriculumId
        })
        // children
        state.children = subscriber.children.map<IChild>(child => {
          return {
            courses: _mapValues(child.courses, (course) => {
              if (course.courseId === -1 || course.curriculumId === -1) {
                return null
              }
              return courseSelectionMapper(course)
            }),
            dob: child.dob || '',
            firstName: child.firstName || '',
            gender: child.gender ? (child.gender === 'M' ? 'M' : 'F') : null,
            lastName: child.lastName || '',
            skipIA: _mapValues(child.courses, (course) => {
              return !!course.skipIA
            })
          }
        })
        // course selections
        state.courseSelections.literacy = subscriber.courseSelections.literacy.map(courseSelectionMapper)
        state.courseSelections.maths = subscriber.courseSelections.maths.map(courseSelectionMapper)
        state.courseSelections.mathsGCSE = subscriber.courseSelections.mathsGCSE.map(courseSelectionMapper)
        state.courseSelections.mathsN5 = subscriber.courseSelections.mathsN5.map(courseSelectionMapper)
        state.courseSelections.reading = subscriber.courseSelections.reading.map(courseSelectionMapper)

        state.upgradingAccount = true

        state.createAccountResult = {
          accountCreationTime: '',
          isCreate: true,
          message: 'Updating account',
          parentFirstName: subscriber.familyInformation.firstName,
          parentLastName: subscriber.familyInformation.lastName,
          parentPassword: subscriber.familyInformation.password!,
          parentUsername: subscriber.familyInformation.loginName!,
          subscriberId: subscriber.subscriberId!,
          children: subscriber.children.map<IResponseCreateAccount['children'][0]>(child => {
            return {
              firstName: child.firstName,
              lastName: child.lastName,
              loginName: child.loginName!,
              password: child.password!,
              userId: Number(child.userId!)
            }
          })
        }
      },
      submitFail (state, message: string) {
        state.submitMessage = message
        state.flashMessage = null
        state.submitting = false
      },
      submitSuccess (state, forceStep) {
        state.submitting = false
        state.submitMessage = null
        state.flashMessage = null
        if (forceStep !== null) {
          state.step = forceStep
        }
      },
      setDocuments (state, documentList: IDocument[]) {
        // use old document state if document was in there beforehand
        // singing points will be reset here
        state.documents = documentList.map((document) => {
          return {
            ...(_find(state.documents, { id: document.id }) || document)
          }
        })
      },
      // setDocumentSigningPoints (state, {index, pointsList}) {
      //   pointsList.forEach(point => {
      //     if (_findIndex(state.documents[index].signingPoints, {name: point.name}) === -1) {
      //       state.documents[index].signingPoints.push(Object.assign({updatedAt: null}, point))
      //     }
      //   })
      // },
      clearAllDocuments (state) {
        state.documents = []
      },
      hideAllDocuments (state) {
        state.documents.forEach(document => {
          Vue.set(document, 'hidden', true)
        })
      },
      setPrivacyPolicyAgreed (state) {
        state.step = STEP_FAMILY_INFORMATION
        state.privacyPolicyAcceptDate = store.getters.moment().toISOString()
      },
      uploadDocumentStart (state, { index }) {
        Vue.set(state.documents[index], 'uploading', true)
      },
      uploadDocumentProgress (state, { index, uploadProgress, size, uploaded }) {
        state.documents[index].uploadError = null
        state.documents[index].url = null
        state.documents[index].uploadError = null
        state.documents[index].uploadProgress = Math.round(uploadProgress * 100)
        state.documents[index].size = size
        state.documents[index].uploaded = uploaded
      },
      uploadDocumentError (state, { index, uploadError }) {
        state.documents[index].uploadProgress = null
        state.documents[index].uploaded = null
        state.documents[index].uploadError = uploadError
        state.documents[index].url = null
        state.documents[index].uploadAt = null
        Vue.set(state.documents[index], 'uploading', false)
      },
      uploadDocumentFinish (state, { index, url }) {
        state.documents[index].uploadProgress = 100
        state.documents[index].uploaded = state.documents[index].size
        state.documents[index].url = url
        state.documents[index].uploadError = null
        state.documents[index].uploadAt = store.getters.moment().toISOString()
        Vue.set(state.documents[index], 'uploading', false)
      },
      setPositionOnly (state, position) {
        state.position = position
      },
      setPosition (state, position) {
        state.position = position
        state.step = STEP_FAMILY_INFORMATION
      },
      returnToFamilyInformation (state) {
        if (state.step === STEP_FINANCE) {
          state.savedFamilyInfo = JSON.stringify(state)
          state.step = STEP_FAMILY_INFORMATION
        }
      },
      removedSavedFamilyInfo (state) {
        state.savedFamilyInfo = null
      },
      setPromoCodes (state, promoCodes: string[]) {
        state.promoCodes = promoCodes
      },
      /**
       *
       * @param state
       * @param param1 The id and qty to set to, use 0 delete an item
       */
      setProductItem (state, item: IItemPurchased) {
        let existingProductIndex = _findIndex(state.otherItemsPurchased, p => p.id === item.id)
        if (existingProductIndex > -1) {
          // product already exists
          if (item.quantity > 0) {
            // update
            Vue.set(state.otherItemsPurchased, existingProductIndex, item)
          } else {
            // remove it
            state.otherItemsPurchased.splice(existingProductIndex, 1)
          }
        } else if (item.quantity > 0) {
          // product does not exist add it if the quantity is > 0
          state.otherItemsPurchased.push(item)
        }
      }
    },
    actions: {
      alert (_opts, message: string) {
        return ((this as any)._vm as Vue).$bvModal.msgBoxOk(message)
      },
      applyPromoCode ({ state, getters, commit }, promoCode: string) {
        // do nothing
        let matchingCode = (getters as IrisGetters).promoCodeData(promoCode)
        if (matchingCode && state.promoCodes.every(p => p.toUpperCase() !== promoCode.toUpperCase())) {
          // need to remove any existing codes which apply the same path as we are going to update that value
          /** any existing promo codes which have the same path */
          const promoCodesWithSamePath = matchingCode.path ? PROMO_CODES.filter(p => {
            if (p.path) {
              const matchingCodesPaths: string[] = valueAsArray(matchingCode.path)
              const thisCodePaths = valueAsArray(p.path)
              return thisCodePaths.some(path => matchingCodesPaths.includes(path))
            }
            return false
          }) : []
          const existingPromoCodesToUndo = state.promoCodes.filter(code => promoCodesWithSamePath.some(e => e.code.toUpperCase() === code.toUpperCase()))
          existingPromoCodesToUndo.forEach(promoCode => {
            const promoCodeData = promoCodesWithSamePath.filter(code => code.code.toUpperCase() === promoCode.toUpperCase())
            promoCodeData.forEach(codeDataToRemove => {
              const paths = valueAsArray(codeDataToRemove.path)
              const valueDefault = valueAsArray(codeDataToRemove.valueDefault)
              paths.forEach((path, index) => {
                commit('updateField', { path, value: valueDefault[index] })
              })
            })
          })

          // remove any existing codes with same path as the value is going to get set anyway
          let promoCodes = state.promoCodes.filter(code => !promoCodesWithSamePath.some(e => e.code.toUpperCase() === code.toUpperCase()))
          promoCodes.push(matchingCode.code)
          // apply the value for the promo code if a path to mutate is set
          if (matchingCode.path) {
            const paths = valueAsArray(matchingCode.path)
            const valueApplied = valueAsArray(matchingCode.valueApplied)
            paths.forEach((path, index) => {
              commit('updateField', { path, value: valueApplied[index] })
            })
          }
          commit('setPromoCodes', promoCodes)
        }
      },
      removePromoCode ({ state, getters, commit }, promoCode: string) {
        let matchingCode = (getters as IrisGetters).promoCodeData(promoCode, { ignoreExtraChecks: true }) // don't check date
        if (state.promoCodes.some(p => p.toUpperCase() === promoCode.toUpperCase())) {
          // remove from state
          commit('setPromoCodes', state.promoCodes.filter(code => code.toUpperCase() !== promoCode.toUpperCase()))
          if (matchingCode && matchingCode.path) {
            const paths = valueAsArray(matchingCode.path)
            const valueDefault = valueAsArray(matchingCode.valueDefault)
            paths.forEach((path, index) => {
              // mutate state if the path is set
              commit('updateField', { path, value: valueDefault[index] })
            })
          }
        }
      },
      cancelUpdatingFamilyInformation ({ state, commit }) {
        if (state.savedFamilyInfo !== null) {
          this.replaceState(JSON.parse(state.savedFamilyInfo))
          commit('removedSavedFamilyInfo')
        }
      },
      confirmEmailsReceived ({ commit, state }) {
        if (!state.submitting && state.step === STEP_CONFIRM_EMAILS_RECEIVED) {
          commit('submitSuccess', STEP_FINANCE)
        }
      },
      removePersistedKey () {
        if (persistedKey) {
          localStorage.removeItem(persistedKey)
        }
      },
      ...actionsForApis(irisDocumentsBucket)
    }
  }) // end new store
  return store
}
