import { SubscriptionType } from './nestjs-interfaces-recurly'

/** Basic contact information */
export interface IBaseContact {
  firstName: string
  lastName: string
  dob: Date
}

/** A primary Address */
export interface IContactAddress {
  city: string
  country: string
  state?: string
  postalCode: string
  street1: string
  street2?: string
}

/** Mark emails as primary */
export interface IEmail {
  email: string
  primary: boolean
}

export enum PhoneType {
  MOBILE = 'MOBILE',
  HOME = 'HOME',
  WORK = 'WORK'
}

export interface IPhone {
  phone: string
  type: PhoneType
}

/** Contact with an address and emails */
export interface IContactWithAddress extends IBaseContact {
  title?: string
  address: IContactAddress
  emails: IEmail[]
}
export enum AltPayerType {
  PRIMARY = 'PRIMARY',
  INITIAL = 'INITIAL',
  SUBSCRIPTION = 'SUBSCRIPTION'
}
export interface IAltPayer extends IContactWithAddress {
  /** alt payer can do more than 1 role */
  type: AltPayerType[]
}

/** Primary parent account */
export interface IPrimaryParent extends IContactWithAddress {
  phones: IPhone[]
}

export enum Gender {
  MALE = 'M',
  FEMALE = 'F'
}
export interface IChild extends IBaseContact {
  gender: Gender
  /** last access Date */
  lastAccessDate?: Date
  /** homeschooled */
  homeschooled?: boolean
}

export enum AdvancePaymentType {
  CASH = 'CASH',
  CHEQUE = 'CHEQUE',
  CARD = 'CARD',
  DEFERRED = 'DEFERRED',
  PAYMENT_PLAN = 'PAYMENT_PLAN'
}
export interface IAdvancePayment {
  type: AdvancePaymentType
  amount: number
  transactionNumber?: string
  paymentDate: Date
}

export interface IDocument {
  /** url to document to download from */
  url: string
  /** short type eg OF DD etc... */
  type: string
}

export enum DebitType {
  DIRECT_DEBIT = 'DIRECT_DEBIT',
  CREDIT_CARD = 'CREDIT_CARD'
}

export interface IDebitInfo {
  /** customer gave immediate notice at time of sale */
  immediateNoticeGiven: boolean
  /** RSDD used */
  remoteSignedDirectDebit: boolean
  /** bypass used as recurly couldn't setup the account */
  directDebitSetupOnRecurlyFailed: boolean
  /** first direct debit date */
  firstDirectDebitDate?: Date | string
  /** type of debit */
  debitType?: DebitType
  /** amount for direct debit */
  debitAmount?: number
}

/* eslint-disable*/
// Enum lead_lead_leadsourcecode
export enum lead_lead_leadsourcecode {
  PublicRelations = 5,
  Seminar = 6,
  TradeShow = 7,
  Web = 8,
  WordofMouth = 9,
  AdWords = 100000000,
  BlueLightCard = 100000007,
  Calendly = 100000009,
  ExhibitionIHS = 100000005,
  GCSEOnline = 134240001,
  GCSETelesales = 134240002,
  MathsforFree = 839270001,
  OtherDigital = 100000001,
  Pressadvertising = 100000002,
  PrimaryTimes = 100000003,
  RadioAdvert = 100000004,
  Schooldistribution = 1,
  CAgenerated = 4,
  CustomerReferral = 2,
  EmailCampaign = 134240003,
  LiveChat = 134240000,
  MarketingCampaign = 100000006,
  Other = 10,
  PurchasedMailingList = 134240006,
  SchoolCampaigns = 100000008,
  SocialMedia = 134240005,
  Telephoneenquiry = 3,
  Website = 134240004,
}


export interface IIrisAccount {
  saleType: SubscriptionType
  /** Primary account parent */
  primaryParent: IPrimaryParent
  /** Alt Payers list (if any) */
  altPayers?: IAltPayer[]
  /** subscriber id */
  subscriberId: number
  /** primary parent username */
  username: string
  /** Authorised persons */
  authorisedPersons?: string
  /** Child list */
  children: IChild[]
  /** payments list */
  payments: IAdvancePayment[]
  /** documents list */
  documents: IDocument[]
  /** direct debit flags */
  directDebit?: IDebitInfo
  /** number of months initial payment made defaults to 1 */
  monthsInitialPayment: number

  /** lead source code */
  leadSourceCode?: lead_lead_leadsourcecode

  /** lead source description */
  sourceCode?: string

  /** force pickup option */
  forcePickup?: boolean

  /** estia labs sales code */
  estiaLabsSalesCode?: string

  /** string to add to end of sales codes in crm */
  codeSuffix?: string
}
