import { formatMobilePhone, reducer } from './helpers'
import _mapValues from 'lodash/mapValues.js'

import { ALTPAYER, DIRECT_DEBIT_ALT_PAYER, DEPOSIT_ALT_PAYER, STEP_OFFICE_PROCESSED_REFERRED, STEP_CUSTOMER_SIGNING, PROMO_CODES } from '@iris/constants'
import { GetterTree } from 'vuex'
import { IrisState, IrisGetters, IChildCourses, DepositPaymentMethods, PaymentMethods, IAddressInfo, IPersonInfo } from './types'
import { IRequestGenerateUnlockCode, IRequestUpdateAccount, IRequestCreateAccount, IRequestValidateUnlockCode, IRequestUpdateFinanceData, IRequestUpdateDocuments, IRequestCompleteFinanceDecline, IRequestStoreIncompleteFinance, IRequestSendDocumentsToCustomer } from '@iris/api'
import { IPosition } from '@iris/components/GetLocation'
import { DeferredDepositPayment, StripeDeferredDepositPayment, StripeCreditCardPayment, StripePaymentPlan, RecurlyCreditCardPayment, RecurlyDeferredPayment, RecurlyPaymentPlanPayment } from './payments/types'
import moment from 'moment'
import { EnrolmentData } from '@iris/apiTypes/EnrolmentData'
import { DebitType, IAccount, SubscriptionType, IIrisAccount, IChild, Gender, IDocument, PhoneType, IAdvancePayment, AdvancePaymentType, IAltPayer, AltPayerType, IPayment } from '@iris/nestjs-interfaces'
import { IAccountCreation } from '@iris/nestjs-interfaces-recurly'
import { Branding, CurrentSaleTypes, PaymentFrequency, ProductType, SaleConfirmationData } from '@iris/queries'
import { ProductFeatureInput } from '@iris/queries'
import i18n from '../i18n'

type CompletedDeferredDepositPayments = (DeferredDepositPayment & Required<Pick<DeferredDepositPayment, 'dueOn'>>) | (StripeDeferredDepositPayment & Required<Pick<StripeDeferredDepositPayment, 'dueOn'>>)

const gettersForApis: GetterTree<IrisState, IrisState> = {
  dataForConfirmationApi: (state, getters: IrisGetters): SaleConfirmationData => {
    const cardPayment = state.payments.payments.find((p): p is RecurlyCreditCardPayment | RecurlyDeferredPayment | RecurlyPaymentPlanPayment => 'cardLastFour' in p)
    // taken from rego logic https://github.com/Future-School/fsds-registration/blob/dcbf5506e66449ad91af17a92b11a80826fc4b94/src/main/java/com/fs/reg/servlets/helpers/UtilityHelper.java#L2200
    let phoneNumber = formatMobilePhone(state.familyInformation.mobilePhone)

    const teacherSessions = Math.floor(getters.currentNewPricingPlan.includedSessions * getters.currentNewPricingPlan.termLength.asMonths())

    return {
      subscriberId: state.createAccountResult!.subscriberId,
      regHitId: state.regHitId,
      saleType: ((): CurrentSaleTypes => {
        if (state.finance.paymentMethod === 'ELP12PLUS') {
          return CurrentSaleTypes.Elp12P         
        }
        if (state.finance.paymentMethod === 'ELD') {
          return CurrentSaleTypes.Eld
        }
        if (state.finance.paymentMethod === 'EB12') {
          return CurrentSaleTypes.Eleb
        }
        if (state.finance.paymentMethod.startsWith('ELP')) {
          return CurrentSaleTypes.Elp
        }
        if (state.finance.paymentMethod.startsWith('ELG')) {
          return CurrentSaleTypes.Elg
        }
        if (state.finance.paymentMethod.startsWith('ELS')) {
          return CurrentSaleTypes.Els
        }
        if (state.finance.paymentMethod.startsWith('ELB')) {
          return CurrentSaleTypes.Elb
        }
        if (state.finance.paymentMethod.startsWith('STS')) {
          return CurrentSaleTypes.ElSapphire
        }
        throw new Error(`Unsupported payment type ${state.finance.paymentMethod}`)
      })(),
      parentInitialUsername: state.createAccountResult!.parentUsername,
      parentInitialPassword: state.createAccountResult!.parentPassword,
      phoneNumber,
      initialPaymentDate: getters['payments/hasDeferredPayment'] ? getters['payments/initialPaymentDate']! : undefined,
      initialPaymentAmount: getters['payments/depositAmount'],
      creditcardLast4: cardPayment ? cardPayment.cardLastFour : undefined,
      firstMonthlyPayment: (getters['instalments/firstPaymentDue'] && getters.hasDirectDebit) ? getters['instalments/firstPaymentDue'].toISOString(true) : undefined,
      monthlyPayment: getters.hasDirectDebit ? getters.currentNewPricingPlan!.membershipFee : undefined,
      subscriptionEndDate: getters.immediateNoticeProvided ? getters.moment().add({ months: getters.minimumNoticeTerm }).endOf('day').toISOString(true) : null,
      minimumTerm: getters.currentNewPricingPlan.termLength?.asMonths() ?? 0,
      regularPaymentFrequency: PaymentFrequency.Month,
      brand: Branding.EstiaLabs,
      features: (() => {
        const featuresList: ProductFeatureInput[] = []
          if (getters.currentNewPricingPlan.termLength) {
            featuresList.push({
              type: ProductType.Membership,
              count: 1,
              description: `Full membership for ${ getters.currentNewPricingPlan.termLength.asMonths() } month${ getters.currentNewPricingPlan.termLength.asMonths() > 1 ? 's' : '' }`,
              includesAll: true
            })
          }
          if (getters.currentNewPricingPlan.includedSessions > 0) {
            featuresList.push({
              type: ProductType.TeacherSessions,
              count: teacherSessions,
              description: `${ teacherSessions } individual live teacher session${ teacherSessions > 1 ? 's' : '' }`,
              includesAll: true
            })
          }
          if (getters.currentNewPricingPlan.includesLearningSystem) {
            featuresList.push({
              type: ProductType.PlatformAccess,
              count: 1,
              description: `Full access to the Estia learning platform`,
              includesAll: true
            })
          }
          if (getters.currentNewPricingPlan.teacherHelpline === true) {
            featuresList.push({
              type: ProductType.TeacherHelpline,
              count: 1,
              description: `Unlimited access to the Teacher Helpline`,
              includesAll: true
            })
          }          
        return featuresList
      })(),
      email: state.familyInformation.email,
      firstName: state.familyInformation.firstName,
      lastName: state.familyInformation.lastName,
      silent: state.bypassPayment,
      termsAndConditionsUrl: state.documents.find(d => d.id === 'TERMS_AND_CONDITIONS')?.url
    }
  },
  dataForCrmConversion: (state, getters: IrisGetters): IIrisAccount => {
    return {
      authorisedPersons: state.familyInformation.authorisedPersons,
      children: state.children.map<IChild>(c => ({
        dob: new Date(c.dob!),
        firstName: c.firstName!,
        gender: c.gender === 'F' ? Gender.FEMALE : Gender.MALE,
        lastName: c.lastName!,
        homeschooled: c.homeSchooling
      })),
      documents: getters.documents.map<IDocument>(d => ({
        type: d.shortId,
        url: d.url!
      })),
      directDebit: {
        immediateNoticeGiven: getters.immediateNoticeProvided,
        remoteSignedDirectDebit: state.directDebitUnableToBeCompletedOnNight,
        directDebitSetupOnRecurlyFailed: state.recurlyByPassUsed,
        firstDirectDebitDate: (getters['instalments/firstPaymentDue'] && getters.hasDirectDebit) ? getters['instalments/firstPaymentDue'].toISOString(true) : undefined,
        debitType: getters.hasDirectDebit ? state.finance.recurlyDDPaymentMethodId ? DebitType.CREDIT_CARD : DebitType.DIRECT_DEBIT : undefined,
        debitAmount: getters.hasDirectDebit ? getters.monthlyInstallment : undefined
      },
      altPayers: ((): IAltPayer[] | undefined => {
        const convertAltPayer = (p: IAddressInfo & IPersonInfo, type: AltPayerType[]): IAltPayer => {
          return {
            address: {
              city: p.city,
              country: p.country,
              postalCode: p.postcode,
              street1: p.address1,
              street2: p.address2
            },
            dob: new Date(p.dob),
            emails: [{
              email: p.email,
              primary: true
            }],
            firstName: p.firstName,
            lastName: p.lastName,
            title: p.title!,
            type
          }
        }
        const payerArray: IAltPayer[] = []
        if (state.altPayer) {
          payerArray.push(convertAltPayer(state.altPayerInformation, [
            AltPayerType.PRIMARY,
            ...(state.payments.payments.some(p => p.paidBy === 'ALTPAYER')) ? [AltPayerType.INITIAL] : [],
            ...(state.finance.dd.ddAltPayer === true) ? [AltPayerType.SUBSCRIPTION] : []
          ])
          )
        }
        if (state.payments.payments.some(p => p.paidBy === 'DEPOSIT_ALT_PAYER')) {
          payerArray.push(convertAltPayer(state.finance.depositAltPayerInfo, [
            AltPayerType.INITIAL,
            ...(state.finance.dd.ddAltPayer === 'DEPOSIT_ALT_PAYER') ? [AltPayerType.SUBSCRIPTION] : []
          ]))
        }
        if (state.finance.dd.ddAltPayer === 'DIRECT_DEBIT_ALT_PAYER') {
          payerArray.push(convertAltPayer(state.finance.dd.ddAltPayerInfo, [
            AltPayerType.SUBSCRIPTION
          ]))
        }

        if (payerArray.length > 0) {
          return payerArray
        }
        return undefined
      })(),
      payments: getters['payments/paymentsWithDollars'].map<IAdvancePayment>((p) => {
        if (p.type === 'payment-plan-stripe') {
          return {
            amount: p.depositAmountInCents / 100,
            paymentDate: new Date(p.createdAt),
            type: AdvancePaymentType.PAYMENT_PLAN,
            transactionNumber: p.receiptNumber
          }
        }
        if (p.type === 'payment-plan-recurly') {
          return {
            amount: p.amountInCents / 3 / 100,
            paymentDate: new Date(p.createdAt),
            type: AdvancePaymentType.PAYMENT_PLAN,
            transactionNumber: p.receiptNumber
          }
        }
        return {
          amount: p.amount,
          paymentDate: (():Date => {
            return new Date('dueOn' in p && p.dueOn ? p.dueOn : p.createdAt)
          })(),
          transactionNumber: 'receiptNumber' in p ? p.receiptNumber : undefined,
          type: (():AdvancePaymentType => {
            if (p.type.includes('deferred')) {
              return AdvancePaymentType.DEFERRED
            }
            if (p.type === 'cash') return AdvancePaymentType.CASH
            if (p.type === 'cheque') return AdvancePaymentType.CHEQUE
            if (p.type === 'manual-refund') throw new Error('Not supported refund type')
            return AdvancePaymentType.CARD
          })()
        }
      }),
      forcePickup: state.forcePickupSale,
      estiaLabsSalesCode: state.finance.paymentMethod,
      primaryParent: {
        address: {
          city: state.address.city,
          country: state.address.country,
          postalCode: state.address.postcode,
          state: state.address.state || undefined,
          street1: state.address.address1,
          street2: state.address.address2
        },
        dob: new Date(state.familyInformation.dob),
        emails: [
          {
            email: state.familyInformation.email,
            primary: true
          },
          ...state.familyInformation.alternativeEmail ? [{
            email: state.familyInformation.alternativeEmail,
            primary: false
          }] : []],
        firstName: state.familyInformation.firstName,
        lastName: state.familyInformation.lastName,
        title: state.familyInformation.title!,
        phones: [
          {
            phone: state.familyInformation.mobilePhone,
            type: PhoneType.MOBILE
          },
          ...state.familyInformation.homePhone ? [{
            phone: state.familyInformation.homePhone,
            type: PhoneType.HOME
          }] : [],
          ...state.familyInformation.workPhone ? [{
            phone: state.familyInformation.workPhone,
            type: PhoneType.WORK
          }] : []
        ]
      },
      saleType: getters.newSubscriptionType,
      subscriberId: state.createAccountResult!.subscriberId,
      username: state.createAccountResult!.parentUsername,
      monthsInitialPayment: 1,
      codeSuffix: (() => {
        /** list of codes which are modifying the 'finance.paymentMethod' path */
        const codesToRemoveList = PROMO_CODES.filter(p => {
          return Array.isArray(p.path) ? p.path.includes('finance.paymentMethod') : p.path === 'finance.paymentMethod'
        }).map(p => p.code)
        /** now remove these codes from the currently applied promo codes - should only be 1 code */
        const codesWhichAreNotPriceRelated = state.promoCodes.filter(code => !codesToRemoveList.includes(code))
        // are are there any codes left over?
        if (codesWhichAreNotPriceRelated.length > 0) {
          // return string padded with a space to append to the et_labssalescode via the api
          return ` ${codesWhichAreNotPriceRelated.join(' ')}`
        }
        // don't append anything just return undefined
        return undefined
      })()
    }
  },
  newSubscriptionType: (state, getters: IrisGetters): SubscriptionType => {
    if (getters.currentNewPricingPlan) {
      return getters.currentNewPricingPlan.baseSubscriptionType
    }
    throw new Error(`Unknown subscription type ${state.finance.paymentMethod}`)
  },
  dataForCreateApi: (state, getters: IrisGetters): IRequestCreateAccount => {
    return {
      ... state.subscriberId ? {
        subscriberId: state.subscriberId
      } : {},
      consultant: state.consultant,
      branchOffice: state.branchOffice,
      familyInformation: {
        title: state.familyInformation.title || '',
        alternativeEmail: state.familyInformation.alternativeEmail,
        alternativeEmailConfirm: state.familyInformation.alternativeEmailConfirm,
        authorisedPersons: state.familyInformation.authorisedPersons,
        dob: state.familyInformation.dob,
        email: state.familyInformation.email,
        emailConfirm: state.familyInformation.emailConfirm,
        firstName: state.familyInformation.firstName,
        homePhone: state.familyInformation.homePhone,
        lastName: state.familyInformation.lastName,
        mobilePhone: state.familyInformation.mobilePhone,
        sendLoginDetailsToMobilePhone: state.familyInformation.sendLoginDetailsToMobilePhone,
        workPhone: state.familyInformation.workPhone
      },
      address: {
        address1: state.address.address1,
        address2: state.address.address2,
        city: state.address.city,
        country: state.address.country,
        postcode: state.address.postcode,
        state: state.address.state || ''
      },
      courseSelections: {
        curriculumState: `${state.regModelMap.DEFAULTCURRICULUMSTATE || state.regModelMap.INSTITUTECODE}${state.address.state}`,
        ...state.courseSelections
      },
      finance: {
        paymentMethod: getters.paymentMethodForCss
      },
      position: state.position || { hasPosition: false },
      maxNumberOfStudents: getters.currentNewPricingPlan?.maxStudentsLimit,
      children: state.children.map(child => {
        return {
          firstName: child.firstName || '',
          lastName: child.lastName || '',
          gender: child.gender || '',
          dob: child.dob || '',
          courses: _mapValues<IChildCourses, EnrolmentData>(child.courses, (course, key) => ({
            ...(course || {
              courseId: 0,
              curriculumId: 0
            }),
            ...(key === 'maths' && { skipIA: !!child.skipIA[key] })
          }))
        }
      })
    }
  },
  dataForRecurlyCreateAccount: (state): IAccountCreation => {
    return {
      email: state.familyInformation.email,
      altEmail: state.familyInformation.alternativeEmail || undefined,
      firstName: state.familyInformation.firstName,
      lastName: state.familyInformation.lastName,
      subscriberId: state.createAccountResult!.subscriberId,
      username: state.createAccountResult!.parentUsername,
      address: {
        city: state.address.city,
        country: state.address.country,
        phone: state.familyInformation.mobilePhone,
        postalCode: state.address.postcode,
        street1: state.address.address1,
        street2: state.address.address2
      }
    }
  },
  dataForRecurlyCreate: (state, getters: IrisGetters): IAccount => {
    const deferredPayments = state.payments.payments.filter((p): p is DeferredDepositPayment | StripePaymentPlan | StripeDeferredDepositPayment => p.finalised && (p.type === 'deferred-stripe' || p.type === 'deferred' || p.type === 'payment-plan-stripe'))
    let initialPaymentAmountDueOn: Date | undefined
    let initialPaymentCustomerNotes: string | undefined
    if (deferredPayments.length) {
      const deferredPaymentsSummary = deferredPayments.map<{
        maxDueDate: number,
        paymentTypeDescription: string
      }>((p) => {
        if (p.type === 'deferred' || p.type === 'deferred-stripe') {
          return {
            maxDueDate: (p.dueOn ? new Date(p.dueOn) : new Date()).valueOf(),
            paymentTypeDescription: `${p.type === 'deferred' ? 'Manual ' : ''}D.I.S.`
          }
        }
        return {
          maxDueDate: Math.max(...(p.instalmentsSchedule || []).map(i => new Date(i.date).valueOf())),
          paymentTypeDescription: 'Payment Plan'
        }
      })
      initialPaymentAmountDueOn = new Date(Math.max(...deferredPaymentsSummary.map(s => s.maxDueDate)))
      initialPaymentCustomerNotes = deferredPaymentsSummary.map(s => s.paymentTypeDescription).join(' and ')
    }

    const initialMonthlyAmount = getters.currentNewPricingPlan.upfrontPayment ? getters.currentNewPricingPlan.upfrontPayment.amount : getters.currentNewPricingPlan!.membershipFee
    const initialPaymentAmountOutstanding = initialMonthlyAmount - getters['payments/paymentsWithDollars'].filter(p => p.type.includes('recurly')).reduce((a, p) => a + p.amount, 0)
    return {
      accountId: state.recurlyAccountId!,
      bypassInitialMandate: true,
      debitStart: getters['instalments/firstPaymentDue'] ? getters['instalments/firstPaymentDue'].toISOString(true) : undefined,
      // initial payment amounts left over outside of recurly
      initialPaymentAmountOutstanding,
      initialPaymentAmountOutstandingReason: initialPaymentAmountOutstanding > 0 ? i18n.t(`payments.title.${state.payments.payments[0].type}`).toString() : undefined,
      initialMonthlyAmount,
      subscriberId: state.createAccountResult!.subscriberId,
      subscriptionType: getters.newSubscriptionType,
      immediateNoticeProvided: getters.immediateNoticeProvided,
      remoteSignDirectDebit: state.directDebitUnableToBeCompletedOnNight,
      monthsInitialPayment: getters.currentNewPricingPlan.upfrontPayment?.period.asMonths() || 1,
      directDebitAmount: getters.currentNewPricingPlan.membershipFee,
      // any automated payments taken outside of recurly (ie direct to stripe)
      payments: getters['payments/paymentsWithDollars'].filter(p => p.finalised && (p.type === 'creditcard-stripe' || p.type === 'exemplarsite-card' || p.type === 'payment-plan-stripe')).map<IPayment>(payment => {
        return {
          amount: payment.type === 'payment-plan-stripe' ? (payment.depositAmountInCents / 100) : payment.amount,
          description: `${payment.type === 'exemplarsite-card' ? 'Manual ' : ''}Credit card payment ${(payment as StripeCreditCardPayment).receiptNumber}`
        }
      }),
      initialPaymentAmountDueOn,
      initialPaymentCustomerNotes,
      addons: getters.currentNewPricingPlan.addons,
      numberOfSessionsOverride: state.numberOfSessionsOverride ?? undefined,
      sessionCostOverride: state.overrideExtraSessionsCost ?? undefined,
      altPayer: (getters.altPayerDirectDebit && getters.hasDirectDebit && !state.finance.recurlyDDPaymentMethodId) ? {
        address: {
          city: getters.altPayerDirectDebitInfo.city,
          country: getters.altPayerDirectDebitInfo.country,
          postalCode: getters.altPayerDirectDebitInfo.postcode,
          street1: getters.altPayerDirectDebitInfo.address1,
          street2: getters.altPayerDirectDebitInfo.address2
        },
        email: getters.altPayerDirectDebitInfo.email,
        firstName: getters.altPayerDirectDebitInfo.firstName,
        lastName: getters.altPayerDirectDebitInfo.lastName
      } : undefined,
      billingInfo: getters.hasDirectDebit ? (state.finance.recurlyDDPaymentMethodId ? {
        type: 'recurly',
        billingId: state.finance.recurlyDDPaymentMethodId
      } : {
        type: 'bacs',
        accountNumber: state.finance.dd.accountNumber!,
        nameOnAccount: state.finance.dd.accountHolder!,
        sortCode: state.finance.dd.sortCode!.replace(/\D/g, ''),
        address: {
          city: getters.altPayerDirectDebitInfo.city,
          country: getters.altPayerDirectDebitInfo.country,
          phone: state.familyInformation.mobilePhone,
          postalCode: getters.altPayerDirectDebitInfo.postcode,
          street1: getters.altPayerDirectDebitInfo.address1,
          street2: getters.altPayerDirectDebitInfo.address2
        }
      }) : undefined
    }
  },
  dataForCompleteFinanceDeclineApi: (state, getters: IrisGetters): IRequestCompleteFinanceDecline => {
    if (!state.createAccountResult) return {} as IRequestCompleteFinanceDecline
    return {
      ...getters.dataForUpdateAccountApi,
      finance: getters.dataForFinanceApi
    }
  },
  dataForUpdateAccountApi: (state, getters: IrisGetters): IRequestUpdateAccount => {
    if (!state.createAccountResult) {
      return {} as IRequestUpdateAccount
    }
    return {
      ...getters.dataForCreateApi,
      children: getters.dataForCreateApi.children.map((child, idx: number) => ({
        ...child,
        userId: state.createAccountResult!.children[idx] ? `${state.createAccountResult!.children[idx].userId}` : '-1'
      })),
      subscriberId: state.createAccountResult.subscriberId
    }
  },
  dataForFinanceApi: (state, getters: IrisGetters): IRequestUpdateFinanceData => {
    if (!state.createAccountResult) return {} as IRequestUpdateFinanceData
    return {
      subscriberId: state.createAccountResult.subscriberId,
      contractAmount: getters.contractAmount,
      dd: {
        accountHolder: state.finance.dd.accountHolder,
        accountNumber: state.finance.dd.accountNumber,
        bankName: state.finance.dd.bankName,
        bankAddress: state.finance.dd.bankAddress,
        sortCode: state.finance.dd.sortCode
      },
      decision: state.finance.decision,
      monthsDeposit: state.finance.monthsDeposit,
      monthsTerm: state.finance.monthsTerm,
      selfDeclaration: state.finance.selfDeclaration,
      proofIncome: Boolean(state.finance.proofIncome).toString(),
      paymentMethod: getters.finalSaleType,
      proofId: state.finance.proofID,
      deferredDepositDate: state.payments!.payments.filter((p):p is CompletedDeferredDepositPayments => (p.type === 'deferred' || p.type === 'deferred-stripe') && !!p.dueOn).reduce<string | null>((initialValue, p) => {
        if (!initialValue || moment(p.dueOn).isBefore(initialValue)) {
          return p.dueOn
        }
        return initialValue
      }, null),
      depositPaymentMethod: state.payments!.payments.reduce<DepositPaymentMethods | ''>((depositPaymentMethod, payment) => {
        // deferred deposits trump all other types
        if (payment.type === 'deferred' || payment.type === 'deferred-stripe') {
          return 'DEFERRED'
        }
        // credit card would be next payment method if set
        if (depositPaymentMethod !== 'DEFERRED' && (payment.type === 'creditcard-stripe' || payment.type === 'exemplarsite-card')) {
          return 'CREDITCARD'
        }
        // cash and cheque are equal
        if (!depositPaymentMethod && payment.type === 'cash') {
          return 'CASH'
        }
        if (!depositPaymentMethod && payment.type === 'cheque') {
          return 'CHEQUE'
        }
        return depositPaymentMethod
      }, ''),
      deposit: getters['payments/totalAmountPaidInCents'] / 100,
      externalDirectDebit: getters.isStripeDirectDebit,
      immediateNoticeProvided: getters.immediateNoticeProvided
    }
  },
  dataForCancelIncompleteAccountApi: (_state, getters: IrisGetters) => getters.dataForDocumentsApi,
  dataForDocumentsApi: (state, getters: IrisGetters): IRequestUpdateDocuments => {
    if (!state.createAccountResult) return {} as IRequestUpdateDocuments
    const result: IRequestUpdateDocuments = {
      subscriberId: state.createAccountResult.subscriberId,
      noCustomerEmail: true, // emails are now to be sent via iris nest
      otherSignerEmails: {},
      externalDirectDebit: getters.isStripeDirectDebit,
      immediateNoticeProvided: getters.immediateNoticeProvided,
      documents: getters.documents.map(doc => {
        return {
          url: doc.url || '',
          pdf: doc.pdf,
          label: doc.shortId,
          id: doc.id,
          signers: []
        }
      })
    }
    if (getters.altPayer) {
      result.otherSignerEmails[ALTPAYER] = state.altPayerInformation.email
    }
    if (getters['payments/firstPaymentWithDepositAltPayer']) {
      result.otherSignerEmails[DEPOSIT_ALT_PAYER] = state.finance.depositAltPayerInfo.email
    }
    if (getters.altPayerDirectDebit === DIRECT_DEBIT_ALT_PAYER) {
      result.otherSignerEmails[DIRECT_DEBIT_ALT_PAYER] = state.finance.dd.ddAltPayerInfo.email
    }
    return result
  },
  dataForUnlockApi: (state, getters: IrisGetters): IRequestValidateUnlockCode => {
    return {
      unlockCode: state.unlockCode || '',
      position: getters.position
    }
  },
  position: (state, getters: IrisGetters): IPosition => {
    return state.position || {
      hasPosition: false,
      message: 'Position not set',
      timestamp: getters.moment().toISOString()
    }
  },
  dataForCompletionCertificateDataApi: (state): number => {
    if (!state.createAccountResult) return -1
    return state.createAccountResult.subscriberId
  },
  dataForGenerateUnlockCodeApi: (state, getters: IrisGetters): IRequestGenerateUnlockCode => {
    if (state.familyInformation.sendUnlockCodeAsPlainMessage === 'email') {
      return {
        email: state.familyInformation.email,
        position: getters.position,
        plainMessage: false
      }
    }
    return {
      phoneNumber: state.familyInformation.mobilePhone,
      position: getters.position,
      plainMessage: !!state.familyInformation.sendUnlockCodeAsPlainMessage
    }
  },
  dataForStoreIncompleteFinanceApi: (state, getters: IrisGetters): IRequestStoreIncompleteFinance => {
    if (!state.createAccountResult) return {} as IRequestStoreIncompleteFinance
    return {
      subscriberId: state.createAccountResult.subscriberId,
      position: getters.position,
      data: JSON.stringify({
        ...reducer(state),
        step: STEP_OFFICE_PROCESSED_REFERRED // 10 is the set finance info step
      })
    }
  },
  dataForSendDocumentsToCustomerApi: (state, getters: IrisGetters): IRequestSendDocumentsToCustomer => {
    if (!state.createAccountResult) return {} as IRequestSendDocumentsToCustomer
    return {
      subscriberId: state.createAccountResult.subscriberId,
      position: getters.position,
      financeData: getters.dataForFinanceApi,
      data: JSON.stringify({
        ...reducer(state),
        step: STEP_CUSTOMER_SIGNING // 12 is the set finance info step
      })
    }
  }
}

export default gettersForApis
